@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background: #000;
  color: #fff;
  line-height: 1.5;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20vw;
  font-weight: 600;
  letter-spacing: 6vw;
  line-height: 1;
  margin: 10vh 0;
  text-transform: uppercase;
  color: #ffbf00;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1.5rem;
  line-height: 20vh;
  margin: 0;
  text-transform: uppercase;
  color: #000;
}
h3 {
  font-family: "Annie Use Your Telescope", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 4vw;
  text-transform: uppercase;
  text-shadow: 1px 2px black;
  rotate: -7deg;
  margin: 0;
  bottom: 5vh;
  right: 6vw;
  position: absolute;
}

.overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.25;
  width: 100%;
  height: 100vh;
  background: url(../images/media-glitch.gif) no-repeat;
  background-size: cover;
}

section {
  width: 100%;
  height: 100vh;
  text-align: center;
}

header {
  position: relative;
}

ul.skills {
  margin: 1vh 0 4vh;
  padding: 0;
}
ul.skills li {
  border: 1px solid #fff;
  border-radius: 16px;
  color: #fff;
  display: inline-block;
  line-height: 32px;
  margin: 5px;
  padding: 0 12px;
}

footer a {
  display: inline-block;
  margin: 1rem;
  transition: ease all 0.3s;
  scale:1;
}
footer a:hover {
  scale:1.2;
}

.projects {
  background: #ffbf00;
  position: relative;
}
.projects .content {
  max-width: 640px;
  width: 90vw;
  height: 80vh;
  overflow: auto;
  margin: 0 auto;
}
.projects table {
  width: 100%;
  border-top: 4px solid #000;
  border-collapse: collapse;
}
.projects table tr td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #000;
  color: #000;
  text-align: left;
  transition: ease all 0.5s;
  background: #ffbf00;
}
.projects table tr:hover td {
  background: #fff;
}

.btn-top {
  display: block;
  position: absolute;
  bottom: 0;
  right: 2rem;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: ease all 0.3s;
  background: rgba(0,0,0,0.75);
  padding-bottom: 0;
}
.btn-top img {
  width: 32px;
}
.btn-top:hover {
  background: rgba(0,0,0,1);
  padding-bottom: 8px;
}

@media (max-width: 767px) {
  h3 {
    font-size: 8vw;
    bottom: -4vh;
  }
  .projects table {
    width: 500px;
  }
  .projects table tr td {
    font-size: 0.8rem;
  }
}